import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import Hero from '../containers/index-page/hero'
import ContactArea from '../containers/global/contact-area'
import TestimonialArea from '../containers/global/testimonial-area/section-one'
import WhatsnewArea from '../containers/index-page/whats-new-area'
import ClientsArea from '../containers/global/clients-area'
import FeaturedServicesArea from '../containers/index-page/featured-services/featured-services-area'
import IntroArea from '../containers/index-page/featured-services/intro-area'


const IndexServices = ({ location, pageFromCMS }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <Header transparent />
    <main className="site-wrapper-reveal">
      <Hero />
      <IntroArea />
      <FeaturedServicesArea />
      <WhatsnewArea />
      <TestimonialArea />
      <ClientsArea />
      <ContactArea />
    </main>
    <Footer />             
  </Layout>
)

export default IndexServices
