import React,{useState} from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import {Container, Row, Col} from 'react-bootstrap'
import Heading from '../../../components/ui/heading'
import Button from '../../../components/ui/button'
import Modal from 'react-bootstrap/Modal'
import {HeroWrapper, HeroWrapperText, PreviewInnerImg} from './hero.style'
import ContactFormArea from '../../../components/forms/contact-form'
// import { Link } from 'gatsby'

const Hero = ({headingSecondary, headingPrimary, buttonStyle}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const heroData = useStaticQuery(graphql `
        query LandingHeroQuery {
            landingJson(id: {eq: "landing-hero-content"}) {
                title
                subtitle
                bgImage {
                    childImageSharp {
                        fluid(maxWidth: 1920, maxHeight: 500, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                image1 {
                    childImageSharp {
                        fluid(maxWidth: 939, maxHeight: 696, quality: 100) {
                            ...GatsbyImageSharpFluid_tracedSVG
                        }
                    }
                }
            }
        }    
    `);



    const {title, subtitle, bgImage, image1} = heroData.landingJson;
    return(
        <HeroWrapper fluid={bgImage.childImageSharp.fluid}>
            <Container fluid className="xp-150">
                <Row className="align-items-center h-100">
                    <Col lg={6}>
                        <HeroWrapperText>
                            {subtitle && <Heading {...headingSecondary}>{subtitle}</Heading>}
                            {title && <Heading {...headingPrimary}>{title}</Heading>}
                            <Button {...buttonStyle} 
                            onClick={handleShow}
                           >Get in Touch</Button>
                            
                        </HeroWrapperText>
                    </Col>
                    <Col lg={6}>
                        <PreviewInnerImg>
                            <Img fluid={image1.childImageSharp.fluid} className="img-fluid min-img animation_images one wow fadeInDown" alt="Preview Hero"/>
                        </PreviewInnerImg>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Contact GDEXperts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactFormArea/>
                </Modal.Body>
            </Modal>

        </HeroWrapper>
    )
}
 
Hero.propTypes = {
    headingSecondary: PropTypes.object,
    headingPrimary: PropTypes.object
}

Hero.defaultProps = {
    headingSecondary: {
        as: 'h6',
        color: 'secondary',
        letterspacing: '2px',
        fontweight: 700,
        fontSize: '16px',
        texttransform: 'uppercase',
        mb: '30px'
    },
    headingPrimary: {
        as: 'h2',
        color: '#fff',
        fontweight: 500,
        mb: '30px'
    },
    buttonStyle: {
        // to: '/contact-us',
        skin: 'light',
        mt: '40px',
        responsive: {
            xlarge: {
                mt: '10px'
            }
        }
    }
}

export default Hero;