import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import { SectionWrap } from './style'

const IntroArea = ({headingStyle}) => {
    const getHistoryIntro = useStaticQuery(graphql`
        query GET_HISTORY_INTRO {
            ourHistoryJson(id: {eq: "history-page-intro"}) {
                title
            }
        }
    `)
    const { ourHistoryJson: { title} } = getHistoryIntro
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        {title && <Heading {...headingStyle}>{parse(title)}</Heading>}
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

IntroArea.defaultProps = {
    headingStyle: {
        as: 'h3',
        mb: '20px',
        child: {
            color: 'primary'
        }
    }
}

export default IntroArea;
